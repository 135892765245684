<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("orderPayment.orderPayment") }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`orderChange.close`)"
            v-on:click="$emit('update:show', false)"
          ></button>
        </div>
        <div class="modal-body">
          <div
            class="text-start custom-input whitebg-input m-2"
            v-if="webshops.length > 0 && webshop !== undefined"
          >
            <div class="row">
              <div class="col-6 mb-3">
                <label class="form-label bold-12 text-gray">
                  {{ $t("order.payment") }}
                </label>
                <v-select
                  :options="
                    webshops
                      .find((e) => e.id === webshop)
                      .paymentMethods.filter(
                        (e) =>
                          (e.supported_currencies === null ||
                            e.supported_currencies.indexOf(currency) > -1) &&
                          (e.delivery_method_id === null ||
                            e.delivery_method_id === deliveryMethod)
                      )
                  "
                  v-model="newPayment.payment_method"
                  label="name"
                  :placeholder="$t(`orderMultiplePayment.selectPayment`)"
                  :reduce="(c) => c.id"
                  append-to-body
                />
              </div>
              <div class="col-6">
                <label class="form-label bold-12 text-gray">
                  {{ $t("orderPayment.payed") }}
                </label>
                <div class="fix-end-input">
                  <input
                    type="number"
                    class="form-control"
                    v-model="newPayment.amount"
                    ref="imp"
                    step="0.01"
                  />
                  <span>{{ currency }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            v-on:click="addNewItems"
            :class="{ 'loading-spin': loadAddBtn }"
            :disabled="loadAddBtn"
          >
            {{ $t("orderChange.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http";
import { useStore } from "vuex";
const bootstrap = require("bootstrap");

export default {
  name: "OrderPayment",
  props: {
    modelValue: Number,
    deliveryMethod: Number,
    currency: String,
    webshop: Number,
  },
  emits: ["update:modelValue", "newItem"],
  data() {
    return {
      store: useStore(),
      myModal: undefined,
      success: false,
      loadAddBtn: false,
      webshops: [],
      newPayment: {
        payment_method: undefined,
        amount: 0,
      },
    };
  },
  computed: {
    currencies() {
      return this.store.state.currencies;
    },
    warehouses() {
      return this.store.state.warehouses;
    },
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
  },
  methods: {
    showModal() {
      if (this.myModal === undefined) {
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
      }
      if (this.webshops.length === 0) {
        http.fetch("/webshops").then((data) => {
          this.webshops = data;
        });
      }

      this.myModal.show();
    },

    addNewItems() {
      this.loadAddBtn = true;
      let change = {
        order_id: this.modelValue,
        payment_method: this.newPayment.payment_method,
        amount: this.newPayment.amount,
      };
      http
        .fetch("/orders/payed", change, true)
        .then((data) => {
          this.$emit("newItem", data);
          this.myModal.hide();
          this.$emit("update:show", false);
          this.loadAddBtn = false;
        })
        .catch(() => {
          this.loadAddBtn = false;
        });
    },
  },
  components: {},
};
</script>
