<template>
  <div>
    <div class="row gap-3 px-3">
      <div class="col-12 col-xl-auto col-xxxl-1_75 p-0">
        <div class="white-box-little text-center">
          <div class="text-area w-100">
            <div class="flex-wrap d-flex ms-3 me-3">
              <div class="d-flex">
                <h2 class="text-black bold-12">{{ prettyId }}</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="white-box-little text-center">
          <div class="text-area w-100">
            <div class="flex-wrap d-flex ms-3 me-3">
              <div class="d-flex">
                <Skeletor
                  v-if="data === undefined"
                  class="text-black bold-12"
                />
                <h2 v-else class="text-black bold-12">
                  {{ data.webshop?.name }}
                  {{ data.shop?.name }}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div v-if="accepted === undefined" class="text-center">
          <Skeletor class="rounded-box ms-auto me-auto mb-2 px-1 ps-0" />
          <Skeletor class="rounded-box ms-auto me-auto mb-2 px-1 ps-0" />
        </div>
        <div v-else class="text-center">
          <button
            class="btn btn-transparent px-1 ps-0"
            data-bs-placement="top"
            :title="$t(`order.receiveMoney`)"
            ref="btn1"
            v-show="data?.shop === null"
            v-on:click="showOrderPaymentModal"
          >
            <span class="rounded-box ms-auto me-auto mb-2 spin-dark">
              <BaseIcon name="money-recive" />
            </span>
          </button>
          <button
            class="btn btn-transparent px-1"
            data-bs-placement="top"
            :title="$t(`order.denyOrder`)"
            v-on:click="denyOrderModal"
            v-show="accepted === null && data?.shop === null"
            ref="btn2"
          >
            <span class="rounded-box ms-auto me-auto mb-2 spin-dark">
              <BaseIcon name="close-circle" />
            </span>
          </button>
          <button
            class="btn btn-transparent px-1"
            data-bs-placement="top"
            :title="
              $t(`order.acceptOrderAndCreateInvoiceWhenPackageSentOutOrPay`)
            "
            v-show="accepted === null && data?.shop === null"
            v-on:click="acceptOrder"
            ref="btn3"
          >
            <span class="rounded-box ms-auto me-auto mb-2 spin-dark">
              <BaseIcon name="tick-circle" />
            </span>
          </button>
          <button
            class="btn btn-transparent px-1 pe-0"
            data-bs-placement="top"
            :title="$t(`order.createInvoice`)"
            ref="btn6"
            :disabled="loadInvoiceBtn"
            v-on:click="generateInvoice"
            :class="{ 'loading-spin': loadInvoiceBtn }"
            v-show="
              (accepted !== false || needGenerateInvoice) &&
              needGenerateInvoice !== false
            "
          >
            <span class="rounded-box ms-auto me-auto mb-2 spin-dark">
              <BaseIcon name="invoice-add" />
            </span>
          </button>
          <button
            class="btn btn-transparent px-1 pe-0"
            :title="$t(`order.sendOrderManagmentLink`)"
            v-if="accepted !== false && modules.webshop"
            :class="{ 'loading-spin': loadSendLinkBtn }"
            :disabled="loadSendLinkBtn"
            v-on:click="sendLink"
          >
            <span class="rounded-box ms-auto me-auto mb-2 spin-dark">
              <BaseIcon name="mail" />
            </span>
          </button>
          <a
            class="btn btn-transparent px-1 pe-0"
            v-if="modules.webshop"
            :title="$t(`order.loginOrder`)"
            :href="tenantUrl + '/order/' + id + '?token=' + token"
            target="_blank"
          >
            <span class="rounded-box ms-auto me-auto mb-2 spin-dark">
              <BaseIcon name="web" />
            </span>
          </a>
        </div>
      </div>
      <div class="col-12 white-box col-xl-3 col-xxl-4 col-xxxl-4_5">
        <div class="blue-line">
          <div class="flex-wrap d-flex gap-3 text-white">
            <div class="d-flex">
              <BaseIcon name="invoice" />
            </div>
            <div class="d-flex">
              <h2 class="bold-xxxl-24 bold-19">
                {{ $t("order.billingInformation") }}
              </h2>
            </div>
            <div class="d-flex ms-auto">
              <button
                v-on:click="editOrderModal"
                class="btn btn-transparent p-0 text-white"
                :title="$t(`order.editOrder`)"
              >
                <BaseIcon name="edit" />
              </button>
            </div>
          </div>
        </div>
        <div class="white-line">
          <div class="row">
            <div class="col-xxl d-flex flex-column">
              <div class="flex-1-1-0" v-if="data === undefined">
                <div class="semibold-16">
                  <Skeletor :width="Math.random() * 21 + 40 + '%'" />
                </div>
                <div class="regular-14 mt-1">
                  <Skeletor :width="Math.random() * 21 + 30 + '%'" />
                </div>
              </div>
              <div v-else class="flex-1-1-0">
                <h3 class="semibold-16" v-if="data.business_name">
                  {{ data.business_name }}
                </h3>
                <h3 class="semibold-16" v-else>
                  {{ data.first_name }} {{ data.last_name }}
                </h3>
                <div class="regular-14 mt-1" v-if="data.type === 1">
                  {{ data.tax_code }}
                </div>
              </div>
              <div>
                <div class="row align-items-baseline mb-2">
                  <h3 class="semibold-16 mt-3 col">
                    {{ $t("order.payment") }}
                  </h3>
                  <div class="col-auto"></div>
                </div>
                <div class="row">
                  <div class="col regular-14">
                    {{ payment?.PaymentMethod?.name }}
                  </div>
                  <div class="col-auto regular-14 text-gray-dark">
                    {{ payment?.funding_source }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xxl-6">
              <h3 class="semibold-16">{{ $t("order.address") }}</h3>
              <div v-if="data === undefined">
                <div class="col regular-14 mt-2">
                  <Skeletor
                    :width="Math.random() * 21 + 30 + '%'"
                    class="me-1"
                  />
                  <Skeletor :width="Math.random() * 5 + 5 + '%'" />
                </div>
                <div class="regular-14 mt-1">
                  <Skeletor :width="Math.random() * 21 + 50 + '%'" />
                </div>
                <div class="regular-14">
                  <Skeletor :width="Math.random() * 21 + 20 + '%'" />
                </div>
                <div class="regular-14 mt-3">
                  <Skeletor :width="Math.random() * 21 + 40 + '%'" />
                </div>
              </div>
              <div v-else>
                <div class="col regular-14 mt-2">
                  {{ data.invoice_street }} {{ data.invoice_house }}
                </div>
                <div class="col regular-14 mt-1">
                  {{ data.invoice_city }}
                </div>
                <div class="col regular-14" v-if="data.invoice_post_code">
                  {{ data.invoice_post_code }}
                </div>
                <div class="col regular-14" v-if="data.invoice_state">
                  {{ data.invoice_state }}
                </div>
                <div class="col regular-14 mt-3">
                  <Country v-model="data.country" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12 white-box col-xl-3 col-xxl-4 col-xxxl-4_5"
        v-if="data?.shop !== null"
      >
        <div class="blue-line">
          <div class="flex-wrap d-flex gap-3 text-white">
            <div class="d-flex">
              <BaseIcon name="invoice" />
            </div>
            <div class="d-flex">
              <h2 class="bold-xxxl-24 bold-19">
                {{ $t("order.salesInformation") }}
              </h2>
            </div>
          </div>
        </div>
        <div class="white-line">
          <div class="row">
            <div class="col-xxl-6">
              <h3 class="semibold-16">{{ $t("order.cashier") }}</h3>
              <div v-if="data === undefined">
                <div class="col regular-14 mt-2">
                  <Skeletor
                    :width="Math.random() * 21 + 30 + '%'"
                    class="me-1"
                  />
                  <Skeletor :width="Math.random() * 5 + 5 + '%'" />
                </div>
              </div>
              <div v-else>
                <div class="col regular-14 mt-2">
                  {{ data?.user?.first_name }} {{ data?.user?.last_name }}
                </div>
                <div class="col regular-14 mt-2">
                  {{ formatDate(data?.created_at) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12 col-xl-3 col-xxl-4 col-xxxl-4_5 white-box"
        v-if="data?.shop === null"
      >
        <div class="blue-line">
          <div class="flex-wrap d-flex gap-3 text-white">
            <div class="d-flex">
              <BaseIcon name="delivery-info" />
            </div>
            <div class="d-flex">
              <h2 class="bold-xxxl-24 bold-19">
                {{ $t("order.deliveryInformation") }}
              </h2>
            </div>
            <div class="d-flex ms-auto">
              <button
                v-on:click="editOrderModal"
                class="btn btn-transparent p-0 text-white"
                :title="$t(`order.editOrder`)"
              >
                <BaseIcon name="edit" />
              </button>
            </div>
          </div>
        </div>
        <div class="white-line">
          <div class="row">
            <div class="col-xxl-6">
              <div class="col-lg d-flex flex-column">
                <div v-if="delivery === undefined" class="flex-1-1-0">
                  <div class="semibold-16">
                    <Skeletor :width="Math.random() * 21 + 80 + '%'" />
                  </div>
                  <div class="regular-14 mt-1">
                    <Skeletor :width="Math.random() * 21 + 50 + '%'" />
                  </div>
                  <div class="regular-14 mt-1">
                    <Skeletor :width="Math.random() * 21 + 40 + '%'" />
                  </div>
                </div>
                <div v-else class="flex-1-1-0">
                  <h3 class="semibold-16">
                    {{ data.first_name }} {{ data.last_name }}
                  </h3>
                  <div class="regular-14 mt-1">{{ data.email }}</div>
                  <div class="regular-14 mt-1">{{ data.phone }}</div>
                </div>
                <div>
                  <div class="row align-items-baseline mb-2">
                    <h3 class="semibold-16 mt-3 col">
                      {{ $t("order.delivery2") }}
                    </h3>
                    <div class="col-auto"></div>
                  </div>
                  <div class="row" v-if="delivery === undefined">
                    <div class="col regular-14">
                      <Skeletor :width="Math.random() * 10 + 40 + '%'" />
                    </div>
                    <div class="col-auto regular-14 text-gray-dark">
                      <Skeletor width="80px" />
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div class="col regular-14">
                      {{ delivery.DeliveryMethod.name }}
                    </div>
                    <div class="col-auto regular-14 text-gray-dark">
                      {{ delivery.public_tracking_code }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xxl-6">
              <h3 class="semibold-16">{{ $t("order.address") }}</h3>
              <div v-if="delivery === undefined">
                <div class="col regular-14 mt-2">
                  <Skeletor
                    :width="Math.random() * 21 + 30 + '%'"
                    class="me-1"
                  />
                  <Skeletor :width="Math.random() * 5 + 5 + '%'" />
                </div>
                <div class="regular-14 mt-1">
                  <Skeletor :width="Math.random() * 21 + 50 + '%'" />
                </div>
                <div class="regular-14">
                  <Skeletor :width="Math.random() * 21 + 20 + '%'" />
                </div>
                <div class="regular-14 mt-3">
                  <Skeletor :width="Math.random() * 21 + 40 + '%'" />
                </div>
              </div>
              <div v-else>
                <div class="regular-14 mt-2" v-if="delivery.street">
                  {{ delivery.street }} {{ delivery.house }}
                </div>
                <div class="regular-14 mt-2" v-else>
                  {{ delivery.package_point }}
                </div>
                <div class="regular-14 mt-1">{{ delivery.city }}</div>
                <div class="regular-14">{{ delivery.post_code }}</div>
                <div class="regular-14 mt-3">
                  <Country v-model="delivery.country" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl col-xxxl-1_5 p-0">
        <Skeletor
          class="white-box-little text-center"
          v-if="status === undefined"
        />
        <div
          v-else
          class="white-box-little text-center"
          :class="statuses[status].color"
        >
          <div class="text-area">
            <BaseIcon name="bag" class="my-2 d-block" />
            <h2 class="bold-12 mb-3">
              {{ statuses[status].name }}
            </h2>
          </div>
        </div>
        <Skeletor
          class="white-box-little text-center"
          v-if="paymentStatus === undefined"
        />
        <div
          v-else
          class="white-box-little text-center"
          :class="paymentStatuses[paymentStatus].color"
        >
          <div class="text-area">
            <BaseIcon
              :name="paymentStatuses[paymentStatus].icon"
              class="my-2 d-block"
            />
            <h2 class="bold-12 mb-3">
              {{ paymentStatuses[paymentStatus].name }}
            </h2>
          </div>
        </div>
        <Skeletor
          class="white-box-little text-center"
          v-if="deliveredStatus === undefined"
        />
        <div
          v-else
          class="white-box-little text-center"
          :class="deliveredStatuses[deliveredStatus].color"
        >
          <div class="text-area">
            <BaseIcon
              :name="deliveredStatuses[deliveredStatus].icon"
              class="my-2 d-block"
            />
            <h2 class="bold-12 mb-3">
              {{ deliveredStatuses[deliveredStatus].name }}
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div v-if="comment" class="nature-white-box my-3">
      <div class="white-box-top"></div>
      <h2 class="bold-16">{{ $t("order.commentFromTheCustomer") }}</h2>
      <p>{{ comment }}</p>
    </div>
    <div class="nature-white-box">
      <div class="white-box-top"></div>
      <TopBar
        v-model:line-options="lineOptions"
        v-model:rows="rows"
        v-model:items="products"
        v-model:selectedLineOption="selectedLineOption"
        :show-currencies="false"
        :show-delete="false"
        :show-languages="false"
        :show-select="false"
        v-on:addNewRow="addNewProduct"
      />
      <one-by-one-table
        v-model:rows="rows"
        v-model:items="products"
        v-on:delete="(e) => showDeleteModal(e)"
        v-on:change="(e) => showDeleteModal(e, true)"
        :show-add="false"
        :show-change="data?.shop === null"
        :show-del="data?.shop === null"
        check-delete-row="quantity"
        type="products"
      />
      <div class="flex-wrap d-flex">
        <div class="d-flex ms-auto gap-3 mx-3 fs-7 align-items-center">
          {{ $t("order.delivery") }}
          <span class="fs-4 my-0">
            <edit-on-click
              v-model="deliveryPrice"
              type="price"
              :editable="false"
            />
          </span>
        </div>
      </div>
      <div class="flex-wrap d-flex" v-if="paymentFee && paymentFee?.amount > 0">
        <div class="d-flex ms-auto gap-3 mx-3 fs-7 align-items-center">
          Payment fee:
          <span class="fs-4 my-0">
            <edit-on-click
              v-model="paymentFee"
              type="price"
              :editable="false"
            />
          </span>
        </div>
      </div>
      <div
        class="flex-wrap d-flex"
        v-if="discountPrice && discountPrice?.amount < 0"
      >
        <div class="d-flex ms-auto gap-3 mx-3 fs-7 align-items-center">
          {{ $t("order.discount") }}
          <span class="fs-4 my-0">
            <edit-on-click
              v-model="discountPrice"
              type="price"
              :editable="false"
            />
          </span>
        </div>
      </div>
      <div class="flex-wrap d-flex">
        <div class="d-flex ms-auto gap-3 mx-3 fs-4 align-items-center">
          {{ $t("pos.total") }}
          <span class="fs-2 my-0">
            <edit-on-click v-model="fullPrice" type="price" :editable="false" />
          </span>
        </div>
      </div>
    </div>
    <h3 class="mt-5 mb-4 bold-24">{{ $t("order.timeline") }}</h3>
    <div class="col">
      <button
        class="btn btn-no-r"
        :class="activeTimeline === 'packages' ? 'btn-primary' : 'btn-secondary'"
        v-on:click="activeTimeline = 'packages'"
      >
        <span>
          <BaseIcon name="box" /> {{ $t("order.packageManagement") }}
        </span>
      </button>
      <button
        class="btn btn-no-r btn-no-l"
        :class="
          activeTimeline === 'messageLog' ? 'btn-primary' : 'btn-secondary'
        "
        v-on:click="activeTimeline = 'messageLog'"
      >
        <span> <BaseIcon name="mail" /> {{ $t("order.messageLog") }} </span>
      </button>
      <button
        class="btn btn-no-l"
        :class="activeTimeline === 'support' ? 'btn-primary' : 'btn-secondary'"
        v-on:click="activeTimeline = 'support'"
      >
        <span>
          <BaseIcon name="messages" /> {{ $t("order.clientInteractions") }}
        </span>
      </button>
    </div>
    <section v-if="activeTimeline === 'packages'" class="mb-4">
      <div
        class="nature-white-box mt-4"
        v-for="(pack, i) in timeline"
        :key="pack.id"
      >
        <div class="white-box-top"></div>
        <div class="row m-3 ms-0 mb-4">
          <div class="col-2">
            <h4 class="bold-24">
              {{
                $t(`order.package`, {
                  ordinal_suffix_of: ordinal_suffix_of(timeline.length - i - 1),
                })
              }}
            </h4>
            <div class="semibold-16">{{ pack?.pack?.name }}</div>
          </div>
          <div
            class="col-2"
            :class="{ 'text-gray': delivery.status === 7 }"
            v-for="delivery in pack?.pack?.orderDeliveries.filter(
              (e) => e.refused_at == null
            )"
            :key="delivery.id"
          >
            <div class="bold-16">{{ delivery.DeliveryMethod?.name }}</div>
            <div class="semibold-16">{{ delivery?.public_tracking_code }}</div>
          </div>
          <div class="col" v-if="pack.pack.orderDeliveries[0]">
            <div>
              {{ pack.pack.orderDeliveries[0].street }}
              {{ pack.pack.orderDeliveries[0].house }}
            </div>
            <div>
              {{ pack.pack.orderDeliveries[0].city }},
              {{ pack.pack.orderDeliveries[0].post_code }}
            </div>
          </div>
          <div class="col">
            <span
              v-for="payment in pack.pack.orderPayments.filter(
                (e, index) =>
                  e.PaymentMethod !== null &&
                  e.payment_status !== 1 &&
                  pack.pack?.orderPayments !== undefined &&
                  pack.pack?.orderPayments?.findIndex(
                    (f) => f.PaymentMethod?.name === e.PaymentMethod?.name
                  ) === index
              )"
              :key="payment.id"
              class="me-1 d-inline-block"
            >
              {{ payment.PaymentMethod?.name }}
            </span>
          </div>
          <div class="col">
            <div
              v-if="
                pack.pack.orderDeliveries &&
                pack.pack.orderDeliveries.length > 0 &&
                pack.pack.orderDeliveries[0].payed_price
              "
            >
              <span class="me-2">{{ $t("order.deliveryPrice") }}</span>
              <b
                >{{ pack.pack.orderDeliveries[0].payed_price }}
                {{ fullPrice?.currency }}</b
              >
            </div>
            <div
              v-if="
                pack.pack.orderDeliveries &&
                pack.pack.orderDeliveries.length > 0 &&
                pack.pack.orderDeliveries[0].service_price_in_base_currency
              "
            >
              <span class="me-2">{{ $t("order.deliveryFee") }}</span>
              <b
                >{{
                  pack.pack.orderDeliveries[0].service_price_in_base_currency
                }}
                EUR</b
              >
            </div>
          </div>
          <div class="col-auto">
            <button
              class="btn btn-outline-primary"
              data-bs-placement="top"
              :title="$t(`order.createDelivery`)"
              ref="btn4"
              :disabled="loadShipBtn"
              v-on:click="generateShipping(pack.pack.id)"
              :class="{ 'loading-spin': loadShipBtn }"
              v-show="
                accepted !== false &&
                pack.pack.orderDeliveries[pack.pack.orderDeliveries.length - 1]
                  ?.public_tracking_code === null &&
                pack.pack.orderDeliveries[pack.pack.orderDeliveries.length - 1]
                  ?.external_code === null &&
                data?.shop === null &&
                status < 3
              "
            >
              <BaseIcon name="box-add" />
            </button>
            <button
              class="btn btn-outline-primary"
              data-bs-placement="top"
              :title="$t(`order.setDeliverySuccesses`)"
              ref="btn5"
              :disabled="loadShipBtn"
              v-on:click="finishShipping(pack.pack.id)"
              :class="{ 'loading-spin': loadShipBtn }"
              v-show="
                pack.pack.orderDeliveries[pack.pack.orderDeliveries.length - 1]
                  ?.public_tracking_code !== null &&
                data?.shop === null &&
                status < 3
              "
            >
              <BaseIcon name="flag" />
            </button>
            <button
              class="btn btn-outline-primary"
              data-bs-placement="top"
              :title="$t(`order.setDeliveryRefused`)"
              ref="btn7"
              :disabled="loadShipBtn"
              v-on:click="refuseShipping(pack.pack.id)"
              :class="{ 'loading-spin': loadShipRefuseBtn }"
              v-show="
                pack.pack.orderDeliveries[pack.pack.orderDeliveries.length - 1]
                  ?.public_tracking_code !== null &&
                data?.shop === null &&
                status < 3
              "
            >
              <BaseIcon name="truck-remove" />
            </button>
            <button
              class="btn btn-outline-primary"
              v-if="
                pack.pack.orderDeliveries[0] && accepted !== false && status < 3
              "
              v-on:click="deliveryPriceChange(pack.pack.orderDeliveries[0])"
            >
              <BaseIcon name="edit-sqare" />
            </button>
            <button
              v-for="orderDelivery in pack.pack.orderDeliveries.filter(
                (orderDelivery) =>
                  orderDelivery.refused_at == null &&
                  orderDelivery.delivered_at == null &&
                  orderDelivery.posted_at == null &&
                  (orderDelivery.public_tracking_code !== null ||
                    orderDelivery.external_code !== null)
              )"
              :key="orderDelivery.id"
              class="btn btn-outline-primary"
              v-on:click="deleteDelivery(orderDelivery)"
            >
              <BaseIcon name="trash" />
              {{ orderDelivery.public_tracking_code }}
            </button>
          </div>
        </div>
        <div v-if="pack.pack.orderDisputes?.length > 0" class="mx-2">
          <h3>{{ $t(`order.disputeProcess`) }}</h3>
          <div
            v-for="dispute in pack.pack.orderDisputes"
            :key="dispute.id"
            class="mb-3"
          >
            {{ dispute.description }}
            <div v-if="dispute.read_at" class="bold-24">
              {{ $t(`order.disputeClosed`) }} {{ formatDate(dispute.read_at) }}
            </div>
          </div>
          <div v-if="pack.pack.is_dispute" class="mt-3">
            <button
              class="btn btn-outline-primary mr-3"
              v-on:click="acceptDisputeClose(pack.pack.id)"
              :class="{ 'loading-spin': loadDisputeBtn }"
              :disabled="loadDisputeBtn"
            >
              <BaseIcon name="tick-circle" /> {{ $t(`order.acceptReturn`) }}
            </button>
            <button
              class="btn btn-outline-primary"
              v-on:click="setDisputeClose(pack.pack.id)"
              :class="{ 'loading-spin': loadDisputeBtn }"
              :disabled="loadDisputeBtn"
            >
              <BaseIcon name="close-circle" /> {{ $t(`order.refuse`) }}
            </button>
          </div>
        </div>
        <div class="timeline mt-5 me-4">
          <div
            class="timeline-item"
            v-for="action in pack.timeline"
            :key="action.id"
          >
            <div class="timeline-time">{{ formatDate(action.created_at) }}</div>
            <div class="timeline-status">
              <div
                class="timeline-status-icon primary bg-gradient"
                v-if="action.type === 0"
              >
                <BaseIcon name="bag" />
              </div>
              <div
                class="timeline-status-icon dark bg-gradient"
                v-else-if="action.type === 1"
              >
                <BaseIcon name="box2" />
              </div>
              <div
                class="timeline-status-icon failed bg-gradient"
                v-else-if="action.type === 2"
              >
                <BaseIcon name="box2" />
              </div>
              <div
                class="timeline-status-icon primary bg-gradient"
                v-else-if="action.type === 3 || action.type === 5"
              >
                <BaseIcon name="box2" />
              </div>
              <div
                class="timeline-status-icon failed bg-gradient"
                v-else-if="action.type === 6"
              >
                <BaseIcon name="trash" />
              </div>
              <div
                class="timeline-status-icon failed bg-gradient"
                v-else-if="action.type === 4"
              >
                <BaseIcon name="box2" />
              </div>
              <div
                class="timeline-status-icon light bg-gradient"
                v-else-if="action.type === 50"
              >
                <BaseIcon name="billing-info" />
              </div>
              <div
                class="timeline-status-icon failed bg-gradient"
                v-else-if="action.type === 51"
              >
                <BaseIcon
                  :name="
                    paymentStatuses[action.payment_status]?.icon ??
                    'billing-info'
                  "
                />
              </div>
              <div
                class="timeline-status-icon light bg-gradient"
                v-else-if="action.type === 97 || action.type === 94"
              >
                <BaseIcon name="delivery-info" />
              </div>
              <div
                class="timeline-status-icon failed bg-gradient"
                v-else-if="action.type === 91"
              >
                <BaseIcon name="delivery-info" />
              </div>
              <button
                class="timeline-status-icon info bg-gradient"
                v-on:click="
                  printLabel(
                    action.public_tracking_code,
                    action.delivery_method_id
                  )
                "
                v-else-if="
                  action.type === 98 || action.type === 95 || action.type === 93
                "
              >
                <BaseIcon name="delivery-info" />
              </button>
              <div
                class="timeline-status-icon successful bg-gradient"
                v-else-if="action.type === 99 || action.type === 96"
              >
                <BaseIcon name="delivery-info" />
              </div>
              <button
                class="timeline-status-icon dark bg-gradient"
                v-on:click="showReceipt(action.receipt)"
                v-else-if="action.type === 88"
              >
                <BaseIcon name="invoice" />
              </button>
              <button
                class="timeline-status-icon dark bg-gradient"
                v-on:click="downloadInvoice(action.invoice.id)"
                v-else-if="action.type === 89"
              >
                <BaseIcon name="invoice" />
              </button>
            </div>
            <div class="timeline-data">
              <div class="row" v-if="action.type === 88">
                <div class="col">
                  <h4 class="bold-19">{{ $t("order.receiptCreated") }}</h4>
                  <div>
                    {{ $t(`order.number`, { number: action.receipt.number }) }}
                  </div>
                  <div>
                    {{ $t(`order.serial`, { serial: action.receipt.serial }) }}
                  </div>
                  <div>
                    {{
                      $t(`order.taxId`, {
                        tax_number: action.receipt.tax_number,
                      })
                    }}
                  </div>
                  <div>
                    {{
                      $t(`order.cashRegister`, {
                        cash_register_id: action.receipt.cash_register_id,
                      })
                    }}
                  </div>
                </div>
                <div class="col-auto">
                  <!--<button
                    class="btn btn-outline-primary"
                    v-on:click="downloadInvoice(action.invoice.id)"
                  >
                    <BaseIcon name="invoice-download" />
                  </button>-->
                </div>
              </div>
              <div class="row" v-if="action.type === 89">
                <div class="col">
                  <h4 class="bold-19">{{ $t("order.invoiceCreated") }}</h4>
                  <div>{{ action.invoice?.invoice_number }}</div>
                </div>
                <div class="col-auto">
                  <!--<button
                    class="btn btn-outline-primary"
                    v-on:click="downloadInvoice(action.invoice.id)"
                  >
                    <BaseIcon name="invoice-download" />
                  </button>-->
                </div>
              </div>
              <div class="row" v-if="action.type === 50 || action.type === 51">
                <div class="col">
                  <h4
                    class="bold-19"
                    :class="{ 'text-danger': action.payment_status == 7 }"
                  >
                    {{ action.status }}
                    {{ action.price >= 0 ? "Payment" : "Refund" }}
                    {{ paymentStatuses[action.payment_status]?.name }}
                  </h4>
                  <div class="row">
                    <div class="col col-md-5 col-lg-3">
                      {{ action.PaymentMethod.name }}
                    </div>
                    <div class="col-auto" v-if="action.funding_source">
                      {{ action.funding_source }}
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <span
                    class="fs-3 my-0"
                    :class="action.price >= 0 ? 'text-success' : 'text-danger'"
                  >
                    <EditOnClick
                      :model-value="{
                        amount: action.price,
                        currency: action.currency_code,
                      }"
                      type="price"
                      :editable="false"
                    />
                  </span>
                </div>
              </div>
              <h4 class="bold-19 mt-2" v-if="action.type === 0">
                {{ $t("order.orderCreated") }}
              </h4>
              <h4 class="bold-19 mt-2" v-if="action.type === 1">
                {{ $t("order.packageRefused") }}
              </h4>
              <h4 class="bold-19 mt-2" v-if="action.type === 2">
                {{ $t("order.itemsReturnFromTheOrder") }}
              </h4>
              <h4 class="bold-19 mt-2" v-if="action.type === 3">
                {{ $t("order.itemsAddedToTheOrder") }}
              </h4>
              <h4 class="bold-19 mt-2" v-if="action.type === 4">
                {{ $t("order.itemsRemovedFromTheOrder") }}
              </h4>
              <h4 class="bold-19 mt-2" v-if="action.type === 5">
                {{ $t("order.itemsReceivedInTheWarehouse") }}
              </h4>
              <h4 class="bold-19 mt-2" v-if="action.type === 6">
                {{ $t("order.itemsRemovedFromTheReturnList") }}
              </h4>
              <div class="row" v-if="action.type === 91">
                <div class="col">
                  <h4 class="bold-19">{{ $t("order.deliveryRefused") }}</h4>
                  <div>{{ action.public_tracking_code }}</div>
                </div>
              </div>
              <div class="row" v-if="action.type === 93">
                <div class="col">
                  <h4 class="bold-19">{{ $t("order.trackingCodeCreated") }}</h4>
                  <div>{{ action.public_tracking_code }}</div>
                </div>
                <div class="col-auto">
                  <!--<button
                    class="btn btn-outline-primary"
                    v-on:click="downloadInvoice(action.invoice.id)"
                  >
                    <BaseIcon name="invoice-download" />
                  </button>-->
                </div>
              </div>
              <h4 class="bold-19 mt-2" v-if="action.type === 94">
                {{ $t("order.orderReceivedInTheWarehouse") }}
              </h4>
              <h4 class="bold-19 mt-2" v-if="action.type === 95">
                {{ $t("order.orderSentOutByTheCustomer") }}
              </h4>
              <h4 class="bold-19 mt-2" v-if="action.type === 96">
                {{ $t("order.orderSentOutByTheCustomer") }}
              </h4>
              <h4 class="bold-19 mt-2" v-if="action.type === 97">
                {{ $t("order.orderReceivedByTheCustomer") }}
              </h4>
              <h4 class="bold-19 mt-2" v-if="action.type === 98">
                {{ $t("order.orderOutFromWarehouse") }}
              </h4>
              <h4 class="bold-19 mt-2" v-if="action.type === 99">
                {{ $t("order.orderPackaged") }}
              </h4>
              <one-by-one-table
                v-if="action.items"
                v-model:rows="productChangeRows"
                v-model:items="action.items"
                type="products"
                :fix-height="false"
                :show-add="false"
                :show-del="false"
              />
            </div>
          </div>
        </div>
      </div>
      <router-link
        :to="'/session/' + sessionId"
        class="btn btn-line"
        v-if="sessionId"
      >
        <span> {{ $t("order.viewUserSession") }} </span>
      </router-link>
    </section>
    <section v-if="activeTimeline === 'messageLog'" class="mb-4">
      <div class="nature-white-box mt-4">
        <div class="white-box-top"></div>
        <div class="timeline mt-5 me-4">
          <div
            class="timeline-item"
            v-for="message in messageLog"
            :key="message.id"
          >
            <div class="timeline-time">
              {{ formatDate(message.runned_at ?? message.planned_runned_at) }}
            </div>

            <div class="timeline-status">
              <div class="timeline-status-icon primary bg-gradient">
                <BaseIcon name="mail" />
              </div>
            </div>
            <div class="timeline-data">
              <h4 class="bold-19 mt-2">
                {{ message.title }}
              </h4>
              <div class="row">
                <div class="col-4">{{ $t("global.email") }}:</div>
                <div class="col-8">{{ message.email }}</div>
                <div class="col-4">{{ $t("global.phone") }}:</div>
                <div class="col-8">{{ message.phone }}</div>
                <div class="col-4">{{ $t("order.channel") }}:</div>
                <div class="col-8">{{ message.app_name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="activeTimeline === 'support'" class="mb-4">
      <div class="nature-white-box mt-4">
        <div class="white-box-top"></div>
        <div class="timeline mt-5 me-4">
          <div
            class="timeline-item"
            v-for="message in contactMessages"
            :key="message.id"
          >
            <div class="timeline-time">
              {{ formatDate(message.posted_at ?? message.created_at) }}
            </div>

            <div class="timeline-status">
              <div class="timeline-status-icon primary bg-gradient">
                <BaseIcon name="messages" />
              </div>
            </div>
            <div class="timeline-data">
              <h4 class="bold-19 mt-2" v-if="message.category_name">
                {{ message.category_name }}
              </h4>
              <h4 class="bold-19 mt-2" v-else-if="message.title">
                {{ message.title }}
              </h4>
              <div v-html="message.text.replaceAll('\r\n', '<br>')"></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <OrderChange
      ref="orderChangeModal"
      v-model="id"
      v-if="data !== undefined"
      v-on:newItem="loadData"
      v-model:products="products"
      v-model:default-delivery="delivery"
      v-model:remove-element="removeElement"
      v-model:webshop-id="data.webshop_id"
      v-model:shop-id="data.shop_id"
      v-model:warehouse="data.warehouse_id"
      v-model:delivery-status="deliveredStatus"
      v-model:add-element="addElement"
      :currency="fullPrice?.currency"
    />
    <DenyOrder
      ref="denyOrderModal"
      v-model="id"
      v-if="data !== undefined"
      v-on:newItem="loadData"
      v-model:products="products"
    />
    <OrderPayment
      ref="orderPaymentModal"
      v-model="id"
      :delivery-method="delivery?.delivery_method_id"
      :currency="fullPrice?.currency"
      :webshop="data?.webshop?.id"
      v-on:newItem="loadData"
    />
    <InvoiceModal ref="invoiceModal" v-model="invoice" />
    <ReceiptModal ref="receiptModal" v-model="receipt" />
    <new-sale
      ref="editOrderModal"
      :editing="true"
      v-model="data"
      v-on:save="changeOrder"
    />
    <EditDeliveryModal
      v-model="selectedDelivery"
      ref="editDeliveryModal"
      v-on:change="changeDeliveryPrice"
    />
    <ConfirmModal
      ref="deleteDeliveryModal"
      :title="$t(`order.deleteDelivery`)"
      text="<ul><li>You will delete<b> the delivery</b></li>
<li>You can't undo this action</li></ul><p>Are you sure you want to delete?</p>"
      cancel-button="No, Keep delivery"
      ok-button="Yes, delete delivery"
      v-on:success="deleteDeliveryEvent"
    />
  </div>
</template>

<script>
import Country from "@/components/lists/listItems/Country";
const BaseIcon = defineAsyncComponent(() =>
  import("../../components/icons/BaseIcon.vue")
);
import OneByOneTable from "../../components/lists/OneByOneTable";
import { defineAsyncComponent } from "vue";
import http from "@/modules/http";
import EditOnClick from "@/components/inputs/EditOnClick";
import OrderChange from "@/components/modals/OrderChange";
import date from "@/modules/date";
import OrderPayment from "@/components/modals/OrderPayment";
import deliveredStatuses from "@/modules/DeliveredStatuses";
import paymentStatuses from "@/modules/PaymentStatuses";
import orderStatuses from "@/modules/OrderStatuses";
import TopBar from "../../components/lists/TopBar";
import DenyOrder from "@/components/modals/DenyOrder";
import EditDeliveryModal from "../../components/modals/EditDeliveryModal";
import ReceiptModal from "../../components/modals/ReceiptModal";
import ConfirmModal from "../../components/modals/ConfirmModal";
import printJS from "print-js";
import tenant from "../../modules/tenant";
import { useStore } from "vuex";
const InvoiceModal = defineAsyncComponent(() =>
  import("../../components/modals/InvoiceModal.vue")
);
const NewSale = defineAsyncComponent(() =>
  import("../../components/modals/NewSale.vue")
);

const bootstrap = require("bootstrap");

export default {
  name: "Order",
  components: {
    ConfirmModal,
    ReceiptModal,
    EditDeliveryModal,
    DenyOrder,
    TopBar,
    OrderPayment,
    OrderChange,
    NewSale,
    EditOnClick,
    Country,
    OneByOneTable,
    BaseIcon,
    InvoiceModal,
  },
  data() {
    return {
      store: useStore(),
      deliveredStatuses: deliveredStatuses,
      paymentStatuses: paymentStatuses,
      statuses: orderStatuses,
      data: undefined,
      comment: undefined,
      removeElement: undefined,
      addElement: true,
      prettyId: undefined,
      accepted: undefined,
      fullPrice: undefined,
      deliveryPrice: undefined,
      paymentFee: undefined,
      discountPrice: undefined,
      payment: undefined,
      delivery: undefined,
      deliveredStatus: undefined,
      paymentStatus: undefined,
      status: undefined,
      messageLog: [],
      contactMessages: [],
      timeline: [],
      needGenerateInvoice: undefined,
      loadShipBtn: false,
      loadSendLinkBtn: false,
      loadDisputeBtn: false,
      loadShipRefuseBtn: false,
      loadInvoiceBtn: false,
      invoice: null,
      receipt: null,
      sessionId: null,
      selectedLineOption: 1,
      token: "",
      activeTimeline: "packages",
      lineOptions: [
        {
          id: 1,
          name: "Default",
          rows: ["name", "id"],
        },
      ],
      rows: [
        {
          name: "image",
          key: "image",
          type: "image",
          show: true,
          editable: false,
        },
        {
          name: "Serial",
          key: "serial",
          component: defineAsyncComponent(() =>
            import("../../components/lists/listItems/Serial")
          ),
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Color",
          key: "caracteristics_1",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Name",
          key: "name",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Size",
          key: "caracteristics_2",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Quantity",
          key: "quantity",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Vat",
          key: "vat",
          type: "number",
          show: true,
          postfix: "%",
          editable: false,
        },
        {
          name: "Net price",
          key: "net_price",
          type: "price",
          show: true,
          editable: false,
        },
        {
          name: "Original price",
          key: "original_price",
          type: "price",
          show: true,
          editable: false,
        },
        {
          name: "Payed price",
          key: "payed_price",
          type: "price",
          show: true,
          editable: false,
        },
        {
          name: "Need to send",
          key: "need_to_sent_quantity",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Sent out",
          key: "sent_out_quantity",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "SKU",
          key: "sku",
          type: "string",
          show: true,
          editable: false,
        },
      ],
      productChangeRows: [
        {
          name: "image",
          key: "image",
          type: "image",
          show: true,
          editable: false,
        },
        {
          name: "Serial",
          key: "serial",
          component: defineAsyncComponent(() =>
            import("../../components/lists/listItems/Serial")
          ),
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Color",
          key: "caracteristics_1",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Name",
          key: "name",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Size",
          key: "caracteristics_2",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Quantity",
          key: "quantity",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "SKU",
          key: "sku",
          type: "string",
          show: true,
          editable: false,
        },
      ],
      products: [],
      selectedDelivery: {},
      columnNumbers: [
        this.$t("serialNumbers.first"),
        this.$t("serialNumbers.second"),
        this.$t("serialNumbers.third"),
        this.$t("serialNumbers.fourth"),
        this.$t("serialNumbers.fifth"),
        this.$t("serialNumbers.sixth"),
        this.$t("serialNumbers.seventh"),
        this.$t("serialNumbers.eighth"),
        this.$t("serialNumbers.ninth"),
        this.$t("serialNumbers.tenth"),
      ],
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    tenantUrl() {
      return tenant.getTenantUrl();
    },
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
    modules() {
      return this.store.state.modules;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    ordinal_suffix_of(i) {
      if (i < 10) {
        return this.columnNumbers[i];
      }
      i++;
      if (this.$i18n.locale !== "en") {
        return i + ".";
      }
      let j = i % 10,
        k = i % 100;
      if (j === 1 && k !== 11) {
        return i + "st";
      }
      if (j === 2 && k !== 12) {
        return i + "nd";
      }
      if (j === 3 && k !== 13) {
        return i + "rd";
      }
      return i + "th";
    },
    editOrderModal() {
      this.$refs.editOrderModal.showModal();
    },
    changeOrder() {
      //this.data.delivery = [];
      http.fetch("/orders/" + this.id, this.data, true, "PUT").then((data) => {
        console.log(data);
      });
    },
    loadData() {
      http.fetch("/orders/" + this.id).then((data) => {
        this.products = data.orderItems;
        this.prettyId = data.pretty_id;
        this.token = data.token;
        this.comment = data.comment;
        this.sessionId = data.session_id;
        this.needGenerateInvoice = data.generate_invoice;
        this.accepted = data.accepted;
        this.deliveredStatus = data.delivered_status;
        this.paymentStatus = data.payment_status;
        this.status = data.status;
        this.messageLog = data.eventRunnings;
        this.contactMessages = data.contactMessages;
        this.fullPrice = data.price;
        this.deliveryPrice = {
          currency: data.price.currency,
          amount: 0,
        };
        this.paymentFee = {
          currency: data.price.currency,
          amount: 0,
        };
        this.discountPrice = {
          currency: data.price.currency,
          amount:
            data.extra_discount_amount > 0
              ? data.extra_discount_amount * -1
              : 0,
        };
        this.timeline = [];
        this.payment = undefined;
        this.delivery = undefined;

        for (const product of this.products) {
          product.image =
            product.image !== null
              ? product.image.indexOf("http") === 0
                ? product.image
                : this.mediaServer + "/" + product.image
              : null;
        }

        for (const pack of data.orderPackages) {
          let packageTimeline = [];
          for (const action of pack.orderActions) {
            if (action.type === null) {
              action.type = 0;
            }

            if (action.items) {
              for (const product of action.items) {
                product.image =
                  product.image !== null
                    ? product.image.indexOf("http") === 0
                      ? product.image
                      : this.mediaServer + "/" + product.image
                    : null;
              }
            }

            packageTimeline.push(action);
          }
          for (const action of pack.orderPayments) {
            if (this.payment === undefined) {
              this.payment = action;
            }
            if (
              this.payment.payment_status !== 7 &&
              this.payment.price > 0 &&
              this.payment.payed_fee > 0
            ) {
              this.paymentFee.amount += this.payment.payed_fee;
            }
            if (this.payment.payed_fee > 0 && pack.orderDeliveries.length > 0) {
              pack.orderDeliveries[0].payed_fee = this.payment.payed_fee;
            }
            if (action.payed_at !== null) {
              action.created_at = action.payed_at;
              action.type = 50;
              packageTimeline.push(action);
            } else if (
              action.started_at !== null &&
              action.payment_status > 0
            ) {
              action.created_at = action.started_at;
              action.type = 51;
              packageTimeline.push(action);
            }
          }
          for (const action of pack.orderInvoices) {
            action.type = 89;
            packageTimeline.push(action);
          }
          for (const action of pack.orderReceipts) {
            action.type = 88;
            action.created_at = action.receipt_date;
            packageTimeline.push(action);
          }
          for (const action of pack.orderDeliveries) {
            if (this.delivery === undefined) {
              this.delivery = action;
            }
            let deliveryType = action.type;
            if (action.packaged_at !== null) {
              action.created_at = action.packaged_at;
              action.type = deliveryType === 3 || deliveryType === 9 ? 96 : 99;
              packageTimeline.push(Object.assign({}, action));
            }
            if (action.tracking_code_created_at !== null) {
              action.created_at = action.tracking_code_created_at;
              action.type = 93;

              packageTimeline.push(Object.assign({}, action));
            }
            if (action.posted_at !== null) {
              action.created_at = action.posted_at;
              action.type = deliveryType === 3 || deliveryType === 9 ? 95 : 98;
              packageTimeline.push(Object.assign({}, action));
            }
            if (action.delivered_at !== null) {
              action.created_at = action.delivered_at;
              action.type = deliveryType === 3 || deliveryType === 9 ? 94 : 97;
              packageTimeline.push(Object.assign({}, action));
            }
            if (action.refused_at !== null) {
              action.created_at = action.refused_at;
              action.type = 91;
              packageTimeline.push(Object.assign({}, action));
            }
          }
          for (const delivery of pack.orderDeliveries) {
            this.deliveryPrice.amount += delivery.payed_price;
          }

          packageTimeline = packageTimeline.sort((a, b) => {
            return (
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
            );
          });

          this.timeline.push({
            pack: pack,
            timeline: packageTimeline,
          });
        }

        this.data = {
          created_at: data.created_at,
          warehouse_id: data.warehouse_id,
          webshop: data.webshop,
          shop: data.shop,
          webshop_id: data.webshop_id,
          type: data.business_name === null ? 0 : 1,
          email: data.email,
          phone: data.phone,
          first_name: data.first_name,
          last_name: data.last_name,
          tax_code: data.tax_code,
          eu_tax_code: data.eu_tax_code,
          business_name: data.business_name,
          country_id: data.country_id,
          country: data.country,
          invoice_post_code: data.invoice_address.post_code,
          invoice_city: data.invoice_address.city,
          invoice_street: data.invoice_address.street,
          invoice_house: data.invoice_address.house,
          invoice_state: data.invoice_address.state,
          invoice_area: data.invoice_address.area,
          delivery: this.delivery,
          user: data.user,
          currency: data.price.currency,
        };
        this.data.delivery.method = this.data?.delivery?.delivery_method_id;

        setTimeout(() => {
          new bootstrap.Tooltip(this.$refs.btn1);
          new bootstrap.Tooltip(this.$refs.btn2);
          new bootstrap.Tooltip(this.$refs.btn3);
          new bootstrap.Tooltip(this.$refs.btn4);
          new bootstrap.Tooltip(this.$refs.btn5);
          new bootstrap.Tooltip(this.$refs.btn6);
          new bootstrap.Tooltip(this.$refs.btn7);
        }, 100);
      });
    },
    acceptOrder() {
      http.fetch("/orders/accept", { order_id: this.id }, true).then(() => {
        this.accepted = true;
      });
    },
    denyOrderModal() {
      this.$refs.denyOrderModal.showModal();
    },
    formatDate(val) {
      return date.format(val);
    },
    showDeleteModal(element, addElement = false) {
      this.removeElement = element;
      this.addElement = addElement;
      this.$refs.orderChangeModal.showModal();
    },
    addNewProduct() {
      this.addElement = true;
      this.$refs.orderChangeModal.showModal();
    },
    showOrderPaymentModal() {
      this.$refs.orderPaymentModal.showModal();
    },
    generateShipping(packageId) {
      this.loadShipBtn = true;
      http
        .fetch("/orders/delivery", { order_package_id: packageId }, true)
        .then(() => {
          this.loadData();
          this.loadShipBtn = false;
        })
        .catch(() => {
          this.loadShipBtn = false;
        });
    },
    generateInvoice() {
      this.loadInvoiceBtn = true;
      http
        .fetch("/orders/invoice", { order_id: this.id }, true)
        .then(() => {
          this.loadData();
          this.loadInvoiceBtn = false;
        })
        .catch(() => {
          this.loadInvoiceBtn = false;
        });
    },
    printLabel(code, method) {
      printJS(
        `${tenant.getApiBaseUrl()}/deliveries/label/${method}?code=${code}&_token=${
          this.store.state.token
        }`
      );
    },
    downloadInvoice(invoice) {
      this.invoice = invoice;
      this.$refs.invoiceModal.showModal();
    },
    showReceipt(receipt) {
      this.receipt = receipt;
      this.$refs.receiptModal.showModal();
    },
    deliveryPriceChange(delivery) {
      this.selectedDelivery = delivery;
      this.$refs.editDeliveryModal.showModal();
    },
    changeDeliveryPrice(data) {
      http.fetch(
        "/orders/edit-delivery",
        {
          id: data.id,
          price: data.payed_price,
          fee: data.payed_fee,
        },
        true
      );
    },
    deleteDelivery(delivery) {
      this.selectedDelivery = delivery;
      this.$refs.deleteDeliveryModal.showModal();
    },
    deleteDeliveryEvent() {
      http
        .fetch(
          "/orders/delete-delivery",
          { id: this.selectedDelivery.id },
          true
        )
        .then(() => {
          this.loadData();
        });
    },
    setDisputeClose(packageId) {
      this.loadDisputeBtn = false;
      http
        .fetch("/orders/close-dispute", { id: packageId }, true)
        .then(() => {
          this.loadData();
          this.loadDisputeBtn = false;
        })
        .catch(() => {
          this.loadDisputeBtn = false;
        });
    },
    acceptDisputeClose(packageId) {
      this.loadDisputeBtn = false;
      http
        .fetch("/orders/accept-dispute", { id: packageId }, true)
        .then(() => {
          this.loadData();
          this.loadDisputeBtn = false;
        })
        .catch(() => {
          this.loadDisputeBtn = false;
        });
    },
    sendLink() {
      this.loadSendLinkBtn = true;
      http
        .fetch("/orders/send-link", { id: this.id }, true)
        .then(() => {
          this.loadSendLinkBtn = false;
        })
        .catch(() => {
          this.loadSendLinkBtn = false;
        });
    },
    finishShipping(packageId) {
      this.loadShipBtn = true;
      http
        .fetch(
          "/orders/delivery/success",
          { order_package_id: packageId },
          true
        )
        .then(() => {
          this.loadData();
          this.loadShipBtn = false;
        })
        .catch(() => {
          this.loadShipBtn = false;
        });
    },
    refuseShipping(packageId) {
      this.loadShipRefuseBtn = true;
      http
        .fetch("/orders/delivery/refuse", { order_package_id: packageId }, true)
        .then(() => {
          this.loadData();
          this.loadShipRefuseBtn = false;
        })
        .catch(() => {
          this.loadShipRefuseBtn = false;
        });
    },
  },
};
</script>
